.skill {
    display: flex;
    margin-bottom: 20px;
    justify-content: space-between;

    &__level {
        display: flex;

        &--5 {
            .skill__dot {
                &:nth-child(-n+5) {
                    background-color: #FD7A5A;
                }
            }
        }

        &--4 {
            .skill__dot {
                &:nth-child(-n+4) {
                    background-color: #FD7A5A;
                }
            }
        }

        &--3 {
            .skill__dot {
                &:nth-child(-n+3) {
                    background-color: #FD7A5A;
                }
            }
        }

        &--2 {
            .skill__dot {
                &:nth-child(-n+2) {
                    background-color: #FD7A5A;
                }
            }
        }

        &--1 {
            .skill__dot {
                &:nth-child(-n+1) {
                    background-color: #FD7A5A;
                }
            }
        }
        
    }

    &__dot {
        display: block;
        background-color: #D9D9D9;
        border-radius: 100%;
        width: 20px;
        height: 20px;
        top: 10px;
        right: 10px;
        margin-left: 19px;

        @media only screen and (min-width: 500px) {
            margin-left: 40px;
        }
    }
}
.skills {
    background-color: rgba(255, 185, 146, 0.22);
    margin: 100px 0 0 0 ;
    padding: 50px 0;

    &__title {
        text-align: center;
    }

    &__subtitle {
        font-size: 20px;
        font-weight: 600;
        margin-top: 40px;
    }
}

.container--skills{
    margin: 0 auto;
    max-width: 700px;
    padding: 0 15px;
}
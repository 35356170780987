.projects {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;

    @media only screen and (min-width: 1100px) {
        flex-wrap: nowrap;
    }

    &__title {
        margin-top: 100px;
        padding-top: 80px;
        text-align: center;
        margin-bottom: 50px;
    }

    &-container {
        &--left {
            @media only screen and (min-width: 1100px) {
                flex-basis: 60%;
            }

            display: flex;
            flex-basis: 100%;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        &--right {
            @media only screen and (min-width: 1100px) {
                flex-basis: 40%;
            }

            flex-basis: 100%;
            display: flex;
            flex-wrap: wrap;
        }
    }

    &__legenda {
        display: flex;
        

        &-item {
            position: relative;
            margin-left: auto;
            margin-bottom: 30px;

            &::before {
                content: "";
                background-color: #FBB468;
                border-radius: 100%;
                width: 20px;
                height: 20px;
                position: absolute;
                top: 0;
                left: -30px;
            }
        }
    }
}
@import './Components/Navigation/navigation.scss';
@import './Components/Header/header.scss';
@import './Components/Projects/projects.scss';
@import './Components/Project/project.scss';
@import './Components/Skills/skills.scss';
@import './Components/Skill/skill.scss';
@import './Components/Footer/footer.scss';
@import './Components/Button/button.scss';
@import url('https://fonts.googleapis.com/css2?family=Roboto&family=Space+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat&family=Raleway&display=swap');

$tablet: 500px;
$mobile: 800px;

body {
    font-family: 'Raleway', sans-serif;
    margin: 0;
}

.container {
    padding: 0 20px;

    @media only screen and (min-width: 800px) {
        display: block;
        max-width: 1080px;
        margin: 0 auto;
    }
}

h1 {
    font-family: 'Caveat', sans-serif;
}

.button {
    text-decoration: none;
}
.header {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    &__image {
        &::before {
            position: absolute;
            content: '';
            width: 100%;
            height: 200px;
            background-color: #F8D58C;
            bottom: 0;
            right: 0;
        }
    } 
}

.about {
    &__image {
        &::before {
            position: absolute;
            content: '';
            width: 100%;
            height: 100px;
            background-color: #F8D58C;
            bottom: -100px;
            right: 0;
            z-index: -2;
        }
    }

    @media only screen and (min-width: 800px) {
        display: flex;

        &__image {
            &::after {
                height: 200px;
                bottom: -90px;
            }
        }
    }

    &__irene {
        max-width: 250px;

        @media only screen and (min-width: 500px) {
            max-width: 350px;
        }

        display: block;
        margin: 0 auto;
    }

    &__content {
        @media only screen and (min-width: 500px) {
            text-align: center;
            max-width: 500px;
            margin: 0 auto;
        }

        @media only screen and (min-width: 800px) {
            padding-left: 50px;
            max-width: 500px;
            text-align: left;
            margin: 0;
        }
    }

    &__title {
        color: #FF817E;
    }
}

.introduction {
    display: flex;
    justify-content: space-evenly;
    padding: 80px 0;
    margin-bottom: 200px;

    &-text {
        max-width: 500px;
        padding-top: 100px;
        text-align: center;
        
        &__title {
            font-size: 50px;
            font-family: "Caveat", sans-serif;
            color: #FBB468;
        }

        &__subtitle {
            padding-bottom: 50px;
        }
    }
}
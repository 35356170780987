@import url('https://fonts.googleapis.com/css2?family=Aclonica&display=swap');

.project {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;

    &--medium {
        flex-basis: 43%;
        height: 300px;
        padding: 10px;

        @media only screen and (min-width: 800px) {
            flex-basis: 46%;
        }

        @media only screen and (min-width: 1100px) {
            margin-right: 24px;
            flex-basis: 43%;
        }
    }

    &--big {
        flex-basis: 100%;
        height: 250px;
        margin-top: 27px;
        padding: 10px;
        margin-bottom: 25px;

        @media only screen and (min-width: 1100px) {
            margin-right: 24px;
            margin-bottom: 0;
        }
    }

    &--small {
        flex-basis: 100%;
        height: 125px;
        padding: 10px;
    }

    &--medium-small {
        margin: 25px 0;
        flex-basis: 100%;
        height: 250px;
        padding: 10px;
    }

    &-content {
        text-decoration: none;
        color: inherit;

        &__title {
            margin: 0;
            font-family: 'Caveat', sans-serif;
            font-size: 30px
        }

        &--bottom-left {
            position: absolute;
            bottom: 20px;
        }

        &--top {
            margin-top: 40px;
            margin-left: 20px;

            .project-content__tags {
                position: absolute;
                bottom: 20px;
            }
        }

        &__tag {
            margin-right: 10px;
            display: inline-block;
        }

        &__label {
            background-color: #FBB468;
            border-radius: 100%;
            width: 20px;
            height: 20px;
            position: absolute;
            top: 10px;
            right: 10px;
        }

        &__subtitle {
            margin-top: 5px;
        }
    }

    &--menunu {
        color: #ffffff;

        .project-content .project-content__title {
            font-size: 40px;
            font-family: "Aclonica", sans-serif;
            position: static;
        }
    }

    &--white {
        color: white;
    }

    &--lyf {
        h4, .project-content__tags {
            max-width: 150px;
        }
    }
}
.footer {
    background-color: #fc886e;
    height: 150px;
    padding: 40px 0;
    position: relative;

    &__title {
        margin-top: 0;
        font-size: 45px;
        color: white;
    }

    &__social-media {
        margin-left: auto;
        width: 200px;

        a {
            cursor: pointer;
        }
    }

    &__icon {
        width: 50px;
        height: 50px;
        margin-left: 15px;
    }
}
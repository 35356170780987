.nav {
    display: block;
    margin: 0 auto;
    max-width: 350px;

    &__items {
        display: flex;
        justify-content: space-between;
    }

    &-item {
        padding: 40px 10px;

        &__text {
            text-decoration: none;
            cursor: pointer;
            font-weight: bold;
            color: #ffffff;
        }
    }
}